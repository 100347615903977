
import { Component, Vue } from 'vue-property-decorator'
import { headers } from '@/pages/policies/helpers'

@Component
export default class PoliciesAndTerms extends Vue {
  // data // ***
  values: Record<string, any> = {}
  policies: Record<string, any> = []
  loading = false
  documentUrl =
    'https://sacmacproduction.blob.core.windows.net/documents/SupplierPortal/SupplierTermsAndConditions.pdf'
  termsAndConditionsAccepted = false

  // computed // ***
  get signatureNeeded() {
    if (this.$auth.impersonating) return false
    else return this.policies.some((x: { signatory: any }) => !x.signatory)
  }
  get nextPolicyToSign() {
    return this.policies.find((x: { signatory: any }) => !x.signatory)
  }
  get headers(): Record<string, any>[] {
    return headers(this)
  }

  // methods // ***

  submit() {
    this.loading = true
    this.$emit('agreement')
  }
  onBack() {
    this.$emit('back')
  }

  download(e: any) {
    const link = document.createElement('a')
    link.href = e.item.path
    link.download = e.item.filename
    link.click()
  }
}
