
import { Component, Vue } from 'vue-property-decorator'
import { getSmartLink, getPaymentLink } from '@/utilities/smartLink'
import invoiceRequestUploadLinkComponent from './components/invoiceUploadRequest.vue'
import availabilityRequestComponent from './components/availabilityRequest.vue'
@Component({ components: { invoiceRequestUploadLinkComponent, availabilityRequestComponent } })
export default class SmartLink extends Vue {
  // data // ***
  loading = false
  smartLinkData = null
  link = null
  hasError = false
  errorMessage = ''
  linkType = -1

  // mounted // ***
  mounted() {
    const extractedData = this.$route.query.d as string
    getSmartLink(this.$route.params.id, extractedData)
      .then(x => {
        this.smartLinkData = x!.data
        this.link = x!.link
        this.linkType = x!.link.linkType
      })
      .catch(() => {
        const errors = this.$store.getters['core/smartLinkErrors']
        // const message = errors.join(', ')
        this.errorMessage = errors.message
        this.hasError = true

        // this.$store.dispatch('core/launchSnackbar', { color: 'error', message })
      })
  }

  // methods // ***
  redirectToStripePaymentPage() {
    getPaymentLink(this.$route.params.id).then(x => {
      window.location.href = x!
    })
  }
}
